import logo from "../logo.svg";

export const HomePage = () => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>Medusa 🎐</h1>
      <p>For all your hydrogel calculation needs!</p>
    </header>
  </div>
);
